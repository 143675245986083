<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="addProductForm"
        ref="addProductForm"
        class="custom-form"
        @submit.prevent="submitAddedProduct"
      >
        <v-row>
          <AddressProducts
            :client="client"
            :products="products"
            @addProduct="addProduct"
            @updateProduct="updateProduct"
            @removeProduct="removeProduct"
            @copyProducts="copyProducts"
          />
        </v-row>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        type="submit"
        form="addProductForm"
      >
        Dodaj produkty
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'

import AddressProducts from './Inputs/AddressProducts'
import { mapActions, mapState } from 'vuex'
import { parseAsBasicUnit } from '../../utils'

export default {
  components: {
    DialogFormWrapper,
    AddressProducts
  },
  data() {
    return {
      products: []
    }
  },
  computed: {
    ...mapState({
      isProcessing: state => state.client.isProcessing,

      client: state => state.client.entity,
      address: state => state.layout.dialog.item
    })
  },
  methods: {
    ...mapActions({
      closeDialog: 'layout/closeDialog',
      showSnackbar: 'snackbar/showSnackbar',
      createProduct: 'client/createProduct',

    }),
    copyProducts(products) {
      this.products.push(...products)
    },
    updateProduct({ index, key, value }) {
      this.products[index][key] = value
    },
    addProduct() {
      this.products.push({ debrisTypeId: null, containerTypeId: null, netValue: null })
    },
    removeProduct(index) {
      this.products.splice(index, 1)
    },
    submitAddedProduct() {
      if (this.$refs.addProductForm.validate()) {
        const products = []
        this.products.map(product => {
          const netValue = parseAsBasicUnit(product.netValue)
          product.debrisTypeId.map(debrisTypeId => {
            products.push({
              ...product,
              netValue,
              debrisTypeId
            })
          })
        })

        this.createProduct({ products, addressId: this.address.id }).then(() => {
          this.showSnackbar({ message: ['Dodano produkty'] })
          this.closeDialog()
        })
      }
    }
  }
}
</script>
